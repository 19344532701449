import React, { lazy, Suspense, useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import * as ROUTES from "./constants/routes"
import './styles/styles.css'

const Landing = lazy(() => import ('./pages/landing'));
const NotFound = lazy(() => import ('./pages/notfound'));


function App() {

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 10000)
  }, [])

  return (
    <div className="Main">
      <Router>
        <Suspense fallback={
          <div className="loading" style={{color:"#f7f7f7"}}>
            {isLoading &&
             <div className="loader-content">
                <p>Hi, I'm Loading. Good to see you here.</p>
             </div>
             } 
          </div>
        }>
          <Switch>
            <Route path={ROUTES.landing} exact>
                <Landing />
            </Route>

            <Route>
                <NotFound />
            </Route>

          </Switch>

        </Suspense>
      </Router>
    </div>
  );
}

export default App;
